<template>
  <div class="tags-page">
    <section class="headline">
      <div class="container">
        <h1 class="headline__title">
          #{{ $route.params.slug.split("-").join(" ") }}
        </h1>
      </div>
    </section>
    <section class="list-tags">
      <div class="container list-card-tags">
        <ShimmerCardHomeLandscape
          v-show="tags.loading"
          v-for="(item, index) in 5"
          :key="index"
        />
        <CardFive
          v-show="!tags.loading"
          v-for="item in tags.items"
          :key="item.id"
          :image="
            item.thumbnail && item.thumbnail.large
              ? item.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :imageFromUrl="true"
          :duration="item.timeVideo ? item.timeVideo : '06:00'"
          :channel="item.channel ? item.channel.title : ''"
          :channelSlug="item.channel.slug"
          :programSlug="item.channel.program ? item.channel.program.slug : ''"
          :category="item.category ? item.category.title : ''"
          :categorySlug="item.category ? item.category.slug : ''"
          :title="item.title"
          :isText="item.isText"
          :isAdvertorial="item.isAdvertorial"
          :to="{
            name: item.isText ? 'read-channel-slug' : 'video-channel-slug',
            params: {
              channel: item.channel.slug ? item.channel.slug : '',
              slug: item.slug ? item.slug : '',
            },
          }"
        />
      </div>
      <div
        class="pagination-cards"
        v-if="tags.items.length > 0 && tags.pagination.pages > 1"
      >
        <paginate
          v-if="
            tags.pagination &&
            tags.pagination.pages &&
            tags.pagination.pages > 1
          "
          :page-count="tags.pagination.pages"
          :page-range="3"
          :margin-pages="1"
          prev-class="arrow"
          next-class="arrow"
          :click-handler="clickCallback"
          :container-class="'pagination'"
          :page-class="'page-item'"
          v-model="page"
        >
          <template slot="prevContent">
            <Icons name="arrow-left" color="#757575" />
          </template>
          <template slot="nextContent">
            <Icons name="arrow-right" color="#757575" />
          </template>
        </paginate>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import config from "../../config";
import CardFive from "/components/cards/CardFive.vue";
import Vue from "vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import ShimmerCardHomeLandscape from "../../components/shimmer/CardHomeLandscape";
import Icons from "../../components/Icons.vue";

Vue.component("paginate", Paginate);

export default {
  async asyncData({ app, error, store, route, params }) {
    const response = await app.$axios.$get(
      `${config.CORE_SERVICE_API}tags/${params.slug}?page=1`
    );

    if (response.data.length === 0) {
      return error({ statusCode: 404, message: "Page not found" });
    }

    try {
      store.commit("seo/SET_SEO", {
        title: `Berita dan Informasi ${params.slug
          .split("-")
          .join(" ")} Terkini dan Terbaru Hari ini`,
        desc: `Kumpulan berita dan informasi tentang ${params.slug
          .split("-")
          .join(" ")} terkini dan terbaru hari ini`,
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    ShimmerCardHomeLandscape,
    CardFive,
    Icons,
  },
  head() {
    return this.headReturn;
  },
  data() {
    return {
      page: this.$route.query.page ? this.$route.query.page : 1,
    };
  },
  watch: {
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
          this.$store.dispatch("videos/getTags", {
            tagsSlug: this.$route.params.slug,
            page: this.page,
            firstLoad: true,
          });
        } else {
          this.page = 1;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      tags: (state) => {
        return state.videos.tags;
      },
    }),
  },
  methods: {
    clickCallback(page) {
      this.page = page;
      this.$router.replace({
        name: "tags-slug",
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          page: page,
        },
      });
    },
    async initData() {
      await this.$store
        .dispatch("videos/getTags", {
          tagsSlug: this.$route.params.slug,
          page: this.page,
          firstLoad: true,
        })
        .then((response) => {
          if (this.tags.items.length == 0) {
            return this.$nuxt.error({
              statusCode: 404,
              message: "Oops, ada kesalahan",
            });
          }
        });
    },
    // showMore() {
    //   this.page = this.page + 1;
    //   this.$store.dispatch("videos/getTags", {
    //     tagsSlug: this.$route.params.slug,
    //     page: this.page,
    //   });
    // },
  },
  async fetch() {
    await this.initData();
  },
  // mounted() {
  //   this.initData();
  // },
};
</script>

<style lang="scss" scoped>
.tags-page {
  padding: 48px 0;
  @media only screen and (max-width: 1024px) {
    padding: 30px 0;
  }

  .headline {
    margin-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 24px;
    }

    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 700;
      color: #4a25aa;
      margin-bottom: 16px;
      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }

    &__desc {
      font-size: 16px;
      font-weight: 400;
      color: #757575;
      text-align: center;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        color: #0c0b0d;
      }
    }
  }
  .list-tags {
    .list-card-tags {
      margin-bottom: 48px;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 32px;
      }
    }
    .pagination-cards {
      padding: 24px 0;

      .pagination {
        justify-content: center;
        margin: 0;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          justify-content: center;
        }

        ::v-deep .arrow {
          display: none;
        }

        ::v-deep .page-item {
          a {
            font-size: 12px;
            font-weight: 400;
            color: #757575;
            background: #e0e0e0;
            width: 25px;
            height: 25px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          &.active {
            a {
              background: #4a25aa;
              color: #9e9e9e !important;
            }
          }

          &:hover {
            a {
              background: #4a25aa;
              color: #9e9e9e !important;
            }
          }
        }
      }
    }
    // .button-show-more {
    //   text-align: center;
    //   a {
    //     height: 50px;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    //     border: 1px solid #4a25aa;
    //     border-radius: 5px;
    //     font-size: 14px;
    //     font-weight: 700;
    //     color: #4a25aa;
    //     padding: 0 20px;
    //     @media only screen and (max-width: 1024px) {
    //       height: 40px;
    //       &:hover {
    //         color: #4a25aa !important;
    //       }
    //     }
    //     @media only screen and (min-width: 1024px) {
    //       &:hover {
    //         color: #8166c8 !important;
    //         border-color: #8166c8 !important;
    //       }
    //     }
    //   }
    // }
  }
}
</style>
