var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags-page"},[_c('section',{staticClass:"headline"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"headline__title"},[_vm._v("\n        #"+_vm._s(_vm.$route.params.slug.split("-").join(" "))+"\n      ")])])]),_vm._v(" "),_c('section',{staticClass:"list-tags"},[_c('div',{staticClass:"container list-card-tags"},[_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{directives:[{name:"show",rawName:"v-show",value:(_vm.tags.loading),expression:"tags.loading"}],key:index})}),_vm._v(" "),_vm._l((_vm.tags.items),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tags.loading),expression:"!tags.loading"}],key:item.id,attrs:{"image":item.thumbnail && item.thumbnail.large
            ? item.thumbnail.large
            : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '06:00',"channel":item.channel ? item.channel.title : '',"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"to":{
          name: item.isText ? 'read-channel-slug' : 'video-channel-slug',
          params: {
            channel: item.channel.slug ? item.channel.slug : '',
            slug: item.slug ? item.slug : '',
          },
        }}})})],2),_vm._v(" "),(_vm.tags.items.length > 0 && _vm.tags.pagination.pages > 1)?_c('div',{staticClass:"pagination-cards"},[(
          _vm.tags.pagination &&
          _vm.tags.pagination.pages &&
          _vm.tags.pagination.pages > 1
        )?_c('paginate',{attrs:{"page-count":_vm.tags.pagination.pages,"page-range":3,"margin-pages":1,"prev-class":"arrow","next-class":"arrow","click-handler":_vm.clickCallback,"container-class":'pagination',"page-class":'page-item'},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('template',{slot:"prevContent"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#757575"}})],1),_vm._v(" "),_c('template',{slot:"nextContent"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#757575"}})],1)],2):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }