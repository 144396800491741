<template>
  <div
    class="watch"
    :class="{ removePadding: $route.query.comment === 'show' }"
  >
    <ModalShare @registeredModal="modalShare = $event" />
    <ModalLogin
      :redirect="$route.path"
      @registeredModal="modalLogin = $event"
    />
    <ModalReportComment
      @registeredModal="modalReportComment = $event"
      :commentId="comment_id"
      @event="reportComment"
    />
    <!-- ADS START -->
    <!-- <AdsHorizontalSlim
      class="p-3"
      id="placement_top_video_detail"
    ></AdsHorizontalSlim> -->
    <VideoPageTopAds />
    <!-- ADS END -->
    <div class="container">
      <div class="main-content">
        <div
          class="video"
          :class="{ setHeight: $route.query.comment === 'show' }"
        >
          <ShimmerWatchVideo v-show="loading" />
          <div
            v-show="!loading"
            class="video__wrapper"
            :class="[
              {
                setHeight: $route.query.comment === 'show',
              },
              {
                setHeightSoundCloud:
                  response.data.contentUrl.default === 'soundcloud',
              },
            ]"
          >
            <template
              v-if="
                response.data.contentUrl &&
                response.data.contentUrl.default === 'youtube' &&
                response.data.contentUrl.content.length > 0
              "
            >
              <div id="playerYoutube"></div>
            </template>
            <template
              v-else-if="
                response.data.contentUrl &&
                response.data.contentUrl.default === 'soundcloud' &&
                response.data.contentUrl.content.length > 0
              "
            >
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive-item"
                  :src="response.data.contentUrl.content[0].url"
                ></iframe>
              </div>
            </template>
            <template
              v-else-if="
                response.data.contentUrl &&
                response.data.contentUrl.content.length === 0
              "
              ><ImageResponsive
                :imageUrl="response.data.thumbnail.medium"
                width="877"
                height="489"
                :fromUrl="true"
                class="img-fluid w-100"
                alt="Narasi TV"
              />
            </template>
            <template
              v-else-if="response.data.contentUrl.default === 'jwplayer'"
            >
              <div
                class="ratio ratio-16x9"
                ref="videoPlayer"
                id="video-player"
              ></div>
            </template>
            <template v-else-if="response.data.contentUrl.default === 'narasi'">
              <narasi-player
                :options="{
                  autoplay: true,
                  controls: true,
                  preload: 'auto',
                  poster: `${response.data.contentUrl.content[0].url.replace(
                    'manifest.m3u8',
                    'poster.png'
                  )}`,
                  sources: [
                    {
                      src: response.data.contentUrl.content[0].url,
                      type: 'application/x-mpegURL',
                    },
                  ],
                  html5: {
                    nativeAudioTracks: true,
                    nativeVideoTracks: true,
                    nativeTextTracks: true,
                  },
                }"
              />
            </template>
            <ShimmerWatchVideo v-else />
          </div>
          <div class="video__detail">
            <div class="channel-and-category">
              <p class="channel-name" v-if="response.data.channel">
                <Link
                  :to="{
                    name: 'program-childBrand-slugProgram',
                    params: {
                      childBrand: response.data.channel.program.slug,
                      slugProgram: response.data.channel.slug,
                    },
                    query: {
                      type: 'highlights',
                    },
                  }"
                  >{{ response.data.channel.title }}</Link
                >
              </p>
              <span>&bull;</span>
              <p class="category-name" v-if="response.data.category">
                <Link
                  :to="{
                    name: 'interest',
                    query: {
                      type: response.data.category.slug,
                    },
                  }"
                  >{{ response.data.category.title }}</Link
                >
              </p>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="title-wrapper">
                  <h1 class="title_">
                    {{ response.data.title }}
                  </h1>
                  <div
                    id="accord-1"
                    class="collapsed show-desc"
                    data-bs-toggle="collapse"
                    data-bs-target="#video-desc"
                    aria-expanded="true"
                    aria-controls="video-desc"
                    @click="nextStep('top-accordion')"
                    :class="{ rotate: flipArrow == 'true' }"
                  >
                    <Icons name="arrow-bottom" class="arrow-bottom" />
                  </div>
                </div>
                <div
                  class="share-wrapper share-video"
                  @click="modalShare.show()"
                >
                  <Icons name="bagikan" class="icon" color="#4A25AA" />
                  <p class="text">Bagikan</p>
                </div>
                <div
                  id="video-desc"
                  class="accordion-collapse collapse show desc-wrapper"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    v-if="
                      response.data.articlePart &&
                      response.data.articlePart.length > 0
                    "
                    class="accordion-body"
                  >
                    <p
                      v-for="(article, index) in page !== 'full'
                        ? response.data.articlePart.slice(0, 1)
                        : response.data.articlePart"
                      :key="`desc-article-${index}`"
                      v-html="
                        article.description
                          .replace(/<p><br[\/]?><[\/]?p>/g, '')
                          .replace(/<p>&nbsp;<[\/]?p>/g, '')
                      "
                    ></p>
                  </div>
                  <div
                    v-else
                    class="accordion-body"
                    v-html="
                      response.data.content
                        ? response.data.content
                            .replace(/<p><br[\/]?><[\/]?p>/g, '')
                            .replace(/<p>&nbsp;<[\/]?p>/g, '')
                        : ''
                    "
                  ></div>
                  <div
                    class="tags"
                    v-if="response.data.tags && response.data.tags.length"
                  >
                    <p class="text">Tags:</p>
                    <div class="tag-wrapper">
                      <Link
                        v-for="item in response.data.tags"
                        :key="item.id"
                        :to="{
                          name: 'tags-slug',
                          params: {
                            slug: item.slug,
                          },
                        }"
                        class="tag"
                        >{{ item.topic }}</Link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- VIDEO COMMENT DESKTOP START -->
          <div class="video__comment">
            <div class="title-wrapper header">
              <TitleSection title="KOMENTAR" size="medium" />
              <div class="dropdown filter">
                <div
                  class="dropdown-toggle"
                  id="dropdownMenuSortComment"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <p class="text">{{ sortLabel }}</p>
                  <div class="arrow">
                    <Icons name="arrow-bottom" />
                  </div>
                </div>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuSortComment"
                >
                  <li
                    v-for="(sortItem, indexSort) in sortComment"
                    :key="'sort-' + indexSort"
                    @click="
                      getSortCommentBy(sortItem.param),
                        (sortLabel = sortItem.label)
                    "
                  >
                    <div class="dropdown-item">{{ sortItem.label }}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="comments">
              <div class="type-comment">
                <div class="type-comment__profile-image">
                  <ImageResponsive
                    v-if="
                      $store.state.auth.user && $store.state.auth.user.picture
                    "
                    :imageUrl="$store.state.auth.user.picture"
                    :fromUrl="true"
                  />
                  <ProfilePicture
                    v-else
                    :initial="
                      $store.state.auth.user && $store.state.auth.user.fullname
                        ? $store.state.auth.user.fullname.substr(0, 1)
                        : ''
                    "
                  />
                </div>
                <div class="type-comment__input">
                  <textarea
                    class="form-control"
                    aria-label="With textarea"
                    placeholder="Tulis Komentar..."
                    ref="type_comment_mobile"
                    :rows="btnSend ? 4 : 1"
                    @focus="
                      $store.state.auth.loggedIn
                        ? (btnSend = true)
                        : modalLogin.show()
                    "
                    :maxlength="limitCharComment"
                    v-model="comment"
                  ></textarea>
                  <p class="disclaimer" v-show="btnSend">
                    Anda sepenuhnya bertanggung jawab atas komentar yang
                    diberikan, hindari ujaran melanggar hukum.
                  </p>
                  <div class="btn-wrapper" v-show="btnSend">
                    <div class="btn-cancel" @click="btnSend = false">BATAL</div>
                    <div class="btn-send" @click="postComment('main')">
                      KIRIM
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="comments.loading" class="p-5">
                <div class="dot-spin m-auto"></div>
              </div>
              <div
                class="comments__no"
                v-if="comments.items.length === 0 && !comments.loading"
              >
                <Icons name="no-comment" class="no-comment-icon" />
                <p class="desc">Belum ada komentar</p>
                <p class="sub-desc">
                  Jadi yang pertama mengirimkan komentar dan berinteraksi dengan
                  pengguna lain
                </p>
              </div>
              <div
                v-show="comments && comments.items.length > 0"
                class="comments__card"
                v-for="(item, index_comment) in $store.state.videos.comments
                  .items"
                :key="index_comment"
              >
                <div class="comments__card__profile-image">
                  <ImageResponsive
                    v-if="item.commenter && item.commenter.picture"
                    :imageUrl="item.commenter.picture"
                    :fromUrl="true"
                  />
                  <ProfilePicture
                    v-else
                    :initial="
                      item.commenter && item.commenter.fullname
                        ? item.commenter.fullname.substr(0, 1)
                        : ''
                    "
                  />
                </div>
                <div class="comments__card__detail">
                  <div class="comment-header">
                    <div class="comment-header__copy">
                      <h2 class="comment-header__copy__username">
                        {{ item.commenter.fullname }}
                      </h2>
                      <p class="comment-header__copy__time">
                        {{ item.createdAt }}
                      </p>
                    </div>
                    <div
                      class="comment-header__dots"
                      @click="
                        $store.state.auth.loggedIn
                          ? [modalReportComment.show(), setCommentId(item.id)]
                          : modalLogin.show()
                      "
                    >
                      <Icons name="dots-option" class="dots" />
                    </div>
                  </div>
                  <div class="comment-body">
                    <p
                      class="comment-body__desc"
                      id="desc"
                      v-html="item.comment"
                    ></p>
                  </div>
                  <div
                    class="comment-footer"
                    :class="item.children.length > 0 ? 'addMargin' : ''"
                  >
                    <div
                      class="comment-footer__like"
                      @click="
                        toggleLike(
                          item,
                          index_comment,
                          response.data.slug,
                          'comment'
                        )
                      "
                    >
                      <Icons
                        name="like"
                        :color="item.isLiked ? '#4A25AA' : '#616161'"
                      />
                      <p class="total-like">
                        {{ item.likes == 0 ? "suka" : item.likes + " suka" }}
                      </p>
                    </div>
                    <div
                      class="comment-footer__reply"
                      @click="replyComment(index_comment, item.id)"
                    >
                      <Icons name="reply" />
                      <p class="text">Balas Komentar</p>
                    </div>
                  </div>
                  <!-- REPLY COMMENT START -->
                  <div
                    class="type-comment sub-type-comment"
                    :class="item.children.length > 0 ? 'addPadding' : ''"
                    v-show="index_comment === subComment.indexComment"
                  >
                    <div class="type-comment__profile-image">
                      <ImageResponsive
                        v-if="
                          $store.state.auth.user &&
                          $store.state.auth.user.picture
                        "
                        :imageUrl="$store.state.auth.user.picture"
                        :fromUrl="true"
                      />
                      <ProfilePicture
                        v-else
                        :initial="
                          $store.state.auth.user &&
                          $store.state.auth.user.fullname
                            ? $store.state.auth.user.fullname.substr(0, 1)
                            : ''
                        "
                      />
                    </div>
                    <div class="type-comment__input">
                      <textarea
                        class="form-control"
                        aria-label="With textarea"
                        placeholder="Tulis Komentar..."
                        ref="type_comment_mobile"
                        rows="1"
                        maxlength="400"
                        v-model="subComment.mobile.value"
                      />
                      <p class="disclaimer">
                        Anda sepenuhnya bertanggung jawab atas komentar yang
                        diberikan, hindari ujaran melanggar hukum.
                      </p>
                      <div class="btn-wrapper">
                        <div
                          class="btn-cancel"
                          @click="replyComment(index_comment, item.id)"
                        >
                          BATAL
                        </div>
                        <div class="btn-send" @click="postComment('subMobile')">
                          KIRIM
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- REPLY COMMENT END -->
                  <!-- SUB COMMENT START -->
                  <transition-group name="list">
                    <div
                      v-show="index_comment === subComment.indexShowSubComment"
                      class="comments__card"
                      v-for="(subItem, index_sub_comment) in item.children"
                      :key="'subComment ' + index_sub_comment"
                    >
                      <div class="comments__card__profile-image">
                        <ImageResponsive
                          v-if="subItem.commenter && subItem.commenter.picture"
                          :imageUrl="subItem.commenter.picture"
                          :fromUrl="true"
                        />
                        <ProfilePicture
                          v-else
                          :initial="
                            subItem.commenter && subItem.commenter.fullname
                              ? subItem.commenter.fullname.substr(0, 1)
                              : ''
                          "
                        />
                      </div>
                      <div class="comments__card__detail">
                        <div class="comment-header">
                          <div class="comment-header__copy">
                            <h2 class="comment-header__copy__username">
                              {{ subItem.commenter.fullname }}
                            </h2>
                            <p class="comment-header__copy__time">
                              {{ subItem.createdAt }}
                            </p>
                          </div>
                          <div
                            class="comment-header__dots"
                            @click="
                              $store.state.auth.loggedIn
                                ? [
                                    modalReportComment.show(),
                                    setCommentId(subItem.id),
                                  ]
                                : modalLogin.show()
                            "
                          >
                            <Icons name="dots-option" class="dots" />
                          </div>
                        </div>
                        <div class="comment-body">
                          <p
                            class="comment-body__desc"
                            v-html="subItem.comment"
                          ></p>
                        </div>
                        <div class="comment-footer">
                          <div
                            class="comment-footer__like"
                            @click="
                              toggleLike(
                                subItem,
                                index_comment,
                                response.data.slug,
                                'sub-comment',
                                index_sub_comment
                              )
                            "
                          >
                            <Icons
                              name="like"
                              :color="subItem.isLiked ? '#4A25AA' : '#616161'"
                            />
                            <p class="total-like">
                              {{
                                subItem.likes == 0
                                  ? "suka"
                                  : subItem.likes + " suka"
                              }}
                            </p>
                          </div>
                          <!-- <div class="comment-footer__reply">
                        <p class="text">BALAS</p>
                      </div> -->
                        </div>
                      </div>
                    </div>
                  </transition-group>
                  <div
                    class="show-more-reply"
                    v-show="item.children.length > 0"
                    @click="showSubComment(index_comment)"
                  >
                    <Icons
                      name="arrow-bottom"
                      color="#616161"
                      :class="{
                        rotate:
                          item.children.length ===
                            comments.items[index_comment].children.length &&
                          index_comment === subComment.indexShowSubComment,
                      }"
                    />
                    <p>
                      {{ getReplyText(item.children.length, index_comment) }}
                    </p>
                  </div>
                  <!-- SUB COMMENT END -->
                </div>
              </div>
            </div>
            <div
              v-if="
                !comments.loading &&
                !loadingShowMoreComment &&
                comments.items.length != comments.pagination.total
              "
              class="show-more-comment"
              @click="loadMoreComments()"
            >
              Lihat Komentar Lainnya
            </div>
            <div v-if="loadingShowMoreComment" class="dot-spin-wrapper">
              <div class="dot-spin m-auto"></div>
            </div>
          </div>
          <!-- VIDEO COMMENT DESKTOP END -->
        </div>
        <div class="playlist-video">
          <!-- ADS START -->
          <!-- <AdsHorizontal
          class="mb-3"
          width="300px"
          height="250px"
          id="placement_sidebar_video_detail"
        ></AdsHorizontal> -->
          <VideoPageTopRightAds />
          <!-- ADS END -->
          <div class="playlist-video__selanjutnya">
            <div class="header">
              <TitleSection
                title="SELANJUTNYA"
                size="medium"
                class="header__title"
              />
              <div class="form-check form-switch header__switch">
                <label class="form-check-label" for="flexSwitchCheckDefault"
                  >Autoplay</label
                >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  v-model="autoplay"
                />
              </div>
            </div>
            <div class="body">
              <div class="list-video-selanjutnya">
                <ShimmerCardHomeLandscapeSmall v-if="selanjutnya.loading" />
                <CardNine
                  v-else
                  :image="
                    selanjutnya.items.thumbnail
                      ? selanjutnya.items.thumbnail.large
                      : 'https://narasi.tv/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :duration="
                    selanjutnya.items.timeVideo
                      ? selanjutnya.items.timeVideo
                      : '00:00'
                  "
                  :category="
                    selanjutnya.items.category
                      ? selanjutnya.items.category.title
                      : ''
                  "
                  :channel="
                    selanjutnya.items.channel
                      ? selanjutnya.items.channel.title
                      : ''
                  "
                  :channelSlug="selanjutnya.items.channel.slug"
                  :programSlug="
                    selanjutnya.items.channel &&
                    selanjutnya.items.channel.program
                      ? selanjutnya.items.channel.program.slug
                      : ''
                  "
                  :title="selanjutnya.items.title"
                  :isText="selanjutnya.items.isText"
                  :isAdvertorial="selanjutnya.items.isAdvertorial"
                  :to="
                    selanjutnya.items.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: selanjutnya.items.channel.slug,
                            slug: selanjutnya.items.slug
                              ? selanjutnya.items.slug
                              : $route.params.slug,
                          },
                          query: {
                            ref: 'video-selanjutnya',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: selanjutnya.items.channel.slug,
                            slug: selanjutnya.items.slug
                              ? selanjutnya.items.slug
                              : $route.params.slug,
                          },
                          query: {
                            ref: 'video-selanjutnya',
                          },
                        }
                  "
                  class="card-video-selanjutnya"
                />
              </div>
            </div>
          </div>
          <div class="playlist-video__populer">
            <div class="header">
              <TitleSection
                title="TERPOPULER"
                size="medium"
                class="header__title"
              />
            </div>
            <div class="body">
              <transition-group
                name="list"
                tag="div"
                class="list-video-populer"
              >
                <ShimmerCardHomeLandscapeSmall
                  v-for="item in 5"
                  :key="item"
                  v-show="populer.loading"
                />
                <CardEight
                  v-show="!populer.loading"
                  v-for="item in populer.items"
                  :key="item.id"
                  :image="
                    item.thumbnail
                      ? item.thumbnail.large
                      : 'https://narasi.tv/storage/images/dummy.png'
                  "
                  :imageFromUrl="true"
                  :duration="item.timeVideo ? item.timeVideo : '00:00'"
                  :category="item.category ? item.category.title : ''"
                  :categorySlug="item.category ? item.category.slug : ''"
                  :channel="item.channel ? item.channel.title : ''"
                  :channelSlug="item.channel.slug"
                  :programSlug="
                    item.channel.program ? item.channel.program.slug : ''
                  "
                  :title="item.title"
                  :isText="item.isText"
                  :isAdvertorial="item.isAdvertorial"
                  :to="
                    item.isText
                      ? {
                          name: `read-channel-slug`,
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'terpopuler-detail',
                          },
                        }
                      : {
                          name: 'video-channel-slug',
                          params: {
                            channel: item.channel.slug,
                            slug: item.slug,
                          },
                          query: {
                            ref: 'terpopuler-detail',
                          },
                        }
                  "
                  class="card-video-populer"
                />
              </transition-group>
              <div
                class="button-show-more"
                :class="{ 'mt-5': populer.loadingShowMore }"
              >
                <span
                  v-if="
                    populer.items.length < populer.totalItem &&
                    !populer.loadingShowMore
                  "
                  @click="nextPopuler"
                  >LIHAT LEBIH BANYAK</span
                >
                <div
                  v-if="populer.loadingShowMore"
                  class="dot-spin m-auto"
                ></div>
              </div>
              <AdsHorizontalFixedProps
                class="mt-3"
                width="315px"
                height="290px"
                id="props_placement_before_populer"
              ></AdsHorizontalFixedProps>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ADS START -->
    <!-- <AdsHorizontal
      class="p-3"
      id="placement_footer_video_detail"
    ></AdsHorizontal> -->
    <VideoPageBottomAds />
    <!-- ADS END -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import ModalLogin from "/components/modal/Login.vue";
import ModalShare from "/components/modal/Share.vue";
import ModalReportComment from "/components/modal/ReportComment.vue";
import CardEight from "/components/cards/CardEight.vue";
import CardNine from "/components/cards/CardNine.vue";
import VideoPageTopAds from "/components/ads/artikelVideo/VideoPageTopAds.vue";
import VideoPageTopRightAds from "/components/ads/artikelVideo/VideoPageTopRightAds.vue";
import VideoPageBottomAds from "/components/ads/artikelVideo/VideoPageBottomAds.vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import config from "/config";
import { mapGetters } from "vuex";
import NarasiPlayer from "narasi-player";
import "narasi-player/dist/narasi-player.css";
import ShimmerWatchVideo from "@/components/shimmer/WatchVideo";
import ShimmerCardHomeLandscapeSmall from "@/components/shimmer/CardHomeLandscapeSmall";
import AdsHorizontalFixedProps from "@/components/ads/HorizontalFixedProps";
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";
import Link from "@/components/Link";
import TitleSection from "@/components/TitleSection";
import ProfilePicture from "@/components/ProfilePicture";
import JadwalImsak from "@/components/ramadan/JadwalImsak.vue";

Vue.component("paginate", Paginate);
export default {
  components: {
    AdsHorizontalFixedProps,
    ShimmerCardHomeLandscapeSmall,
    ProfilePicture,
    TitleSection,
    ImageResponsive,
    Icons,
    Link,
    ShimmerWatchVideo,
    NarasiPlayer,
    ModalLogin,
    ModalShare,
    ModalReportComment,
    CardEight,
    CardNine,
    VideoPageTopAds,
    VideoPageTopRightAds,
    VideoPageBottomAds,
    JadwalImsak,
  },
  async asyncData({ app, params, query, store, route, error, redirect }) {
    try {
      store.commit("config/setLoading", true);
      const response = await app.$axios.$get(
        `${config.CORE_SERVICE_API}articles/${params.slug}`
      );

      if (response.data.isText) {
        redirect(
          301,
          query.ref
            ? `/read/${response.data.channel.slug}/${response.data.slug}?ref=${query.ref}`
            : `/read/${response.data.channel.slug}/${response.data.slug}`
        );
      }

      if (response.data.contentUrl.default === "soundcloud") {
        redirect(
          301,
          query.ref
            ? `/podcast/${params.channel}/${response.data.slug}?ref=${query.ref}`
            : `/podcast/${params.channel}/${response.data.slug}`
        );
      }

      if (response.data.isText || response.data.isPodcast) {
        return error({ statusCode: 404, message: "Page not found" });
      }

      if (response.status === 301) {
        redirect(
          301,
          query.ref
            ? `/video/${params.channel}/${response.redirect}?ref=${query.ref}`
            : `/video/${params.channel}/${response.redirect}`
        );
      }
      if (response.data.channel.slug !== params.channel) {
        redirect(
          301,
          query.ref
            ? `/video/${response.data.channel.slug}/${response.data.slug}?ref=${query.ref}`
            : `/video/${response.data.channel.slug}/${response.data.slug}`
        );
      }
      if (query.autoplay) {
        redirect(
          301,
          query.ref
            ? `/video/${response.data.channel.slug}/${params.slug}?ref=${query.ref}`
            : `/video/${response.data.channel.slug}/${params.slug}`
        );
      }

      if (response.data.isAdvertorial) {
        redirect(
          301,
          query.ref
            ? `/video/advertorial/${response.data.channel.slug}/${response.data.slug}?ref=${query.ref}`
            : `/video/advertorial/${response.data.channel.slug}/${response.data.slug}`
        );
      }

      store.commit("seoWithAmp/SET_SEO", {
        title: response.data.title,
        desc: response.data.short ? response.data.short : response.data.content,
        image:
          response.data.thumbnail && response.data.thumbnail.large
            ? response.data.thumbnail.large
            : "https://narasi.tv/storage/images/dummy.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
        ampPath: `${config.BASE_URL}/amp${route.path}`,
      });

      store.commit("config/setLoading", false);

      if (!response.data.category) {
        response.data.category = {
          title: "Uncategorized",
          slug: "uncategorized",
        };
      }

      return { response };
    } catch (err) {
      store.commit("config/setLoading", false);
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  data() {
    return {
      showMoreReply: false,
      btnSend: false,
      url: "",
      flipArrow: "true",
      limit: 5,
      limitCommentMobile: 3,
      limitCommentDesktop: 10,
      limitCharComment: 400,
      loadingShowMoreComment: false,
      comment: "",
      comment_id: null,
      page: 1,
      screenSize: null,
      subComment: {
        commentId: "",
        indexComment: null,
        indexShowSubComment: null,
        desktop: {
          value: "",
        },
        mobile: {
          value: "",
        },
      },
      playerVars: {
        autoplay: 1,
      },
      sortLabel: "Latest Comment",
      sortComment: [
        {
          label: "Latest Comment",
          param: "",
        },
        {
          label: "Most Liked",
          param: "mostLiked",
        },
        {
          label: "Most Replied",
          param: "mostReplied",
        },
      ],
    };
  },
  head() {
    return {
      ...this.headReturn,
    };
  },
  jsonld() {
    if (!this.response.data) {
      return null;
    }

    return [
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Narasi TV",
            item: `${config.BASE_URL}`,
          },
          {
            "@type": "ListItem",
            position: 2,
            name: this.response.data.channel.title,
            item: `${config.BASE_URL}/program/${this.response.data.channel.program.slug}/${this.response.data.channel.slug}?type=highlights`,
          },
          {
            "@type": "ListItem",
            position: 3,
            name: this.response.data.category.title,
            item: `${config.BASE_URL}/interest?type=${this.response.data.category.slug}`,
          },
          {
            "@type": "ListItem",
            position: 4,
            name: this.response.data.title,
          },
        ],
      },
      {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name: this.response.data.title,
        description: `${this.response.data.content
          .replace(/(<([^>]+)>)/gi, "")
          .substring(0, 306)}...`,
        thumbnailUrl: [this.response.data.thumbnail.medium],
        uploadDate: this.$moment(this.response.data.publishDate).toISOString(),
        embedUrl:
          this.response.data.contentUrl.default === "youtube" &&
          this.response.data.contentUrl.content[0]
            ? `https://www.youtube.com/embed/${this.response.data.contentUrl.content[0].url.substr(
                17,
                11
              )}`
            : this.response.data.contentUrl.default === "jwplayer"
            ? `https://cdn.jwplayer.com/players/${this.response.data.contentUrl.mediaId}-LulCuIg8.html`
            : null,
      },
      {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: this.response.data.title
          ? this.response.data.title.substring(0, 110)
          : "",
        image: [this.response.data.thumbnail.medium],
        datePublished: this.$moment(
          this.response.data.publishDate
        ).toISOString(),
        author: {
          "@type": "Person",
          name: this.response.data.createdBy
            ? this.response.data.createdBy
            : "Narasi Distribusi",
        },
        publisher: {
          "@type": "Organization",
          name: "Narasi TV",
          logo: {
            "@type": "ImageObject",
            url: "https://narasi.tv/logo.png",
          },
        },
      },
    ];
  },
  beforeRouteLeave(to, from, next) {
    this.modalLogin.hide();
    this.modalShare.hide();
    this.modalReportComment.hide();
    if (
      to.name !== "video-channel-slug" ||
      to.name !== "podcast-channel-slug"
    ) {
      this.$store.commit("videos/SET_AUTOPLAY", true);
    }
    next();
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.modalLogin.hide();
      this.modalShare.hide();
      this.modalReportComment.hide();
      next(true);
    });
    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
  computed: {
    ...mapGetters({ headReturn: "seoWithAmp/headReturn" }),
    ...mapState({
      loading: (state) => {
        return state.config.isLoading;
      },
      comments: (state) => {
        return state.videos.comments;
      },
      commentsMobile: (state) => {
        return state.videos.commentsMobile;
      },
      selanjutnya: (state) => {
        return state.videos.selanjutnya;
      },
      populer: (state) => {
        return state.videos.populer;
      },
    }),
    autoplay: {
      get() {
        return this.$store.state.videos.autoplay;
      },
      set(value) {
        this.$store.commit("videos/SET_AUTOPLAY", value);
      },
    },
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    async reportComment(params) {
      let data = params;
      this.$axios
        .$post(`${config.CORE_SERVICE_API}comments/${data.commentId}/reports`, {
          type: data.type,
        })
        .then((response) => {
          this.modalReportComment.hide();
          this.$toast.success("Komentar berhasil dilaporkan");
        })
        .catch((e) => {
          this.$toast.error(e.response.data.message);
        });
    },
    toggleLike(comment, index, slug, commentType, indexSubComment = index) {
      if (this.$store.state.auth.loggedIn) {
        this.$store.commit("videos/SET_LIKE_DISLIKE_COMMENT", {
          comment: comment,
          indexComment: index,
          indexSubComment: indexSubComment,
          slug: slug,
          isLiked: comment.isLiked ? false : true,
          commentType: commentType,
        });
      } else {
        this.modalLogin.show();
      }
    },
    setCommentId(param) {
      this.comment_id = param;
    },
    async initData() {
      await this.$store.dispatch("videos/getVideoSelanjutnya", {
        articleSlug: this.$route.params.slug,
      });

      await this.$store.dispatch("videos/getVideoPopuler", {
        limit: this.limit,
      });
    },
    initVideo() {
      setTimeout(() => {
        if (this.response.data.contentUrl.default === "jwplayer") {
          let playlist = this.response.data.contentUrl.content[0].url[1];
          let mediaId = playlist.file.split("/")[4].replaceAll("-720.mp4", "");
          let player = jwplayer("video-player").setup({
            playlist: `https://cdn.jwplayer.com/v2/media/${mediaId}`,
            image: this.response.data.thumbnail.medium,
            title: this.response.data.title,
            description: this.response.data.short,
            aspectratio: "16:9",
            stretching: "uniform",
            width: "100%",
            height: "500",
            ga: {
              gtag: "gtag",
              label: "mediaid",
              trackerName: "",
              trackingobject: "_gaq",
              universalga: "ga",
            },
          });

          const that = this;
          player.on("complete", (event) => {
            if (that.$store.state.videos.autoplay) {
              if (that.selanjutnya.items) {
                that.$toast.success(
                  "Video selanjutnya akan diputar, silahkan tunggu beberapa saat lagi"
                );
              }
              setTimeout(function () {
                if (that.selanjutnya.items) {
                  window.location.href = that.$router.resolve({
                    name: "video-channel-slug",
                    params: {
                      slug: that.selanjutnya.items.channel.slug
                        ? that.selanjutnya.items.channel.slug
                        : $route.params.channel,
                      slug: that.selanjutnya.items.slug
                        ? that.selanjutnya.items.slug
                        : $route.params.slug,
                    },
                  }).href;
                }
              }, 3000);
            }
          });
        } else if (
          this.response.data.contentUrl.default === "youtube" &&
          this.response.data.contentUrl.content.length > 0
        ) {
          const that = this;
          console.log(this.response.data, that.$store.state.videos.autoplay);
          new YT.Player("playerYoutube", {
            videoId:
              this.response.data.contentUrl.content[0] &&
              this.response.data.contentUrl.content[0].url
                ? this.response.data.contentUrl.content[0].url.substr(17, 11)
                : "",
            playerVars: {
              autoplay: that.$store.state.videos.autoplay ? 1 : 0,
              rel: 0,
              modestbranding: 1,
            },
            events: {
              onStateChange: (event) => {
                if (event.data == 0 && that.$store.state.videos.autoplay) {
                  that.ended();
                }
              },
            },
          });
        }
      }, 50);
    },
    async postComment(type) {
      let data = {};
      switch (type) {
        case "main":
          data = {
            articleSlug: this.$route.params.slug,
            comment: this.comment,
          };
          break;
        case "sub":
          data = {
            articleSlug: this.$route.params.slug,
            comment: this.subComment.desktop.value,
            commentId: this.subComment.commentId,
          };
          break;
        case "subMobile":
          data = {
            articleSlug: this.$route.params.slug,
            comment: this.subComment.mobile.value,
            commentId: this.subComment.commentId,
          };
          break;
      }
      if (this.$store.state.auth.loggedIn) {
        if (data.comment) {
          await this.$axios
            .$post(
              data.commentId
                ? `${config.CORE_SERVICE_API}articles/${data.articleSlug}/comments/${data.commentId}`
                : `${config.CORE_SERVICE_API}articles/${data.articleSlug}/comments`,
              {
                comment: data.comment,
              }
            )
            .then((response) => {
              this.$store.dispatch("videos/getComments", {
                articleSlug: this.$route.params.slug,
                page: 1,
                limit:
                  this.screenSize <= 1024
                    ? this.limitCommentMobile
                    : this.limitCommentDesktop,
              });

              this.btnSend = false;
              this.comment = "";
              this.subComment.desktop.value = "";
              this.subComment.mobile.value = "";
              this.$toast.success("Komentar berhasil dikirim");
            })
            .catch((error) => {
              if (error.response.data.statusCode === 400) {
                this.$toast.error(
                  "Komentar Anda tidak terkirim karena melanggar UU ITE"
                );
              } else {
                this.$toast.error(
                  "Maaf, komentar anda tidak terkirim. Silahkan coba kembali"
                );
              }
            });
        } else {
          this.$toast.info("Komentar tidak boleh kosong");
        }
      } else {
        this.modalLogin.show();
      }
    },
    getSortCommentBy(param) {
      this.$store.dispatch("videos/getComments", {
        articleSlug: this.$route.params.slug,
        limit:
          this.screenSize < 1024
            ? this.limitCommentMobile
            : this.limitCommentDesktop,
        sort: param,
      });
    },
    nextPopuler() {
      this.limit = this.limit + 5;
      this.$store.dispatch("videos/getVideoPopuler", {
        limit: this.limit,
        loadingShowMore: true,
      });
    },
    async loadMoreComments() {
      this.loadingShowMoreComment = true;
      this.limitCommentDesktop = this.limitCommentDesktop + 5;
      this.limitCommentMobile = this.limitCommentMobile + 2;
      await this.$store
        .dispatch("videos/getComments", {
          articleSlug: this.$route.params.slug,
          limit:
            this.screenSize < 1024
              ? this.limitCommentMobile
              : this.limitCommentDesktop,
        })
        .then(() => {
          this.loadingShowMoreComment = false;
        });
    },
    replyComment(i, commentId) {
      if (this.$store.state.auth.loggedIn) {
        if (i === this.subComment.indexComment) {
          this.subComment.indexComment = "";
        } else {
          this.subComment.indexComment = i;
        }
        if (commentId) {
          this.subComment.commentId = commentId;
        } else {
          this.subComment.commentId = "";
        }
      } else {
        this.modalLogin.show();
      }
    },
    getReplyText(childrenLength, index) {
      let string = "";
      if (childrenLength > 0) {
        string = "Lihat " + childrenLength + " Balasan Lainnya";
        if (
          childrenLength === this.comments.items[index].children.length &&
          index === this.subComment.indexShowSubComment
        ) {
          string = "Tutup " + childrenLength + " Balasan Lainnya";
        }
      }
      if (childrenLength == 1) {
        string = "Lihat Balasan Lainnya";
        if (
          childrenLength === this.comments.items[index].children.length &&
          index === this.subComment.indexShowSubComment
        ) {
          string = "Tutup Balasan Lainnya";
        }
      }
      return string;
    },
    showSubComment(i) {
      if (i === this.subComment.indexShowSubComment) {
        this.subComment.indexShowSubComment = "";
      } else {
        this.subComment.indexShowSubComment = i;
      }
    },
    ended() {
      if (this.$store.state.videos.autoplay) {
        window.location.href = this.$router.resolve({
          name: "video-channel-slug",
          params: {
            channel: this.selanjutnya.items.channel.slug
              ? this.selanjutnya.items.channel.slug
              : $route.params.channel,
            slug: this.selanjutnya.items.slug
              ? this.selanjutnya.items.slug
              : $route.params.slug,
          },
        }).href;
      }
    },
    nextStep() {
      const element = this.$el.querySelector("#accord-1");
      this.flipArrow = element.getAttribute("aria-expanded");
    },
    onScriptLoaded(event = null) {
      if (event) {
        this.initVideo();
        console.log("Was added");
      } else {
        this.initVideo();
        console.log("Already existed");
      }
    },
  },
  // async fetch() {
  //   await this.initData();
  // },
  mounted() {
    this.initData();
    this.$store.dispatch("videos/getComments", {
      articleSlug: this.$route.params.slug,
      limit:
        this.screenSize < 1024
          ? this.limitCommentMobile
          : this.limitCommentDesktop,
    });

    const script = document.createElement("script");
    // script.onload = this.onScriptLoaded;
    this.initVideo();
    script.type = "text/javascript";
    script.src = "https://cdn.jwplayer.com/libraries/LulCuIg8.js";
    document.head.appendChild(script);
    this.url = window.location.href;
    this.screenSize = window.screen.width;

    if (this.response.data.channel && this.response.data.category) {
      this.$gtm.push({
        event: "Event Program Analytics",
        event_category: this.response.data.channel.title,
        event_action: "Program Analytics VOD Single Video",
        event_label: this.response.data.title,
        value: "Program " + this.response.data.channel.title,
        nama_program: this.response.data.channel.title,
        nonInteraction: true,
      });
      this.$gtm.push({
        event: "Event Interest Analytics",
        event_category: this.response.data.category.title,
        event_action: "Interest Content Video",
        event_label: this.response.data.title,
        value: "Interest " + this.response.data.category.title,
        nonInteraction: true,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active {
  transition: all 0.5s;
}
.list-enter /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.dot-spin-wrapper {
  height: 40px;
  margin-top: 30px;
}
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.72984px 12.72984px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.72984px -12.72984px 0 0 rgba(152, 128, 255, 0);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.72984px 12.72984px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.72984px 12.72984px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.72984px 12.72984px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.72984px 12.72984px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.72984px -12.72984px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.72984px -12.72984px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #9880ff,
      -12.72984px -12.72984px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.72984px -12.72984px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.72984px 12.72984px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0), -12.72984px -12.72984px 0 0 #9880ff;
  }
}
.watch {
  padding: 16px 0 48px;
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
  }
  &.removePadding {
    padding: 0;
  }
  #modal-share {
    .modal-header {
      padding: 30px 30px 20px;
      border: none;
      .modal-title {
        font-size: 24px;
        font-weight: 500;
        color: #4a25aa;
      }
      .close {
        cursor: pointer;
        opacity: 1;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 30px 30px;
      .sosmed-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        svg {
          width: 32px;
          height: 32px;
          margin-right: 12px;
        }
        .sosmed {
          margin: 0;
          font-size: 18px;
          font-weight: 400;
          color: #828282;
        }
      }
    }
  }
  .main-content {
    display: flex;
    padding: 8px 0;
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      // padding: 0;
    }
    .video {
      width: 70%;
      padding-right: 30px;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding: 0;
      }
      &.setHeight {
        @media only screen and (max-width: 1024px) {
          // COMMENT MOBILE START
          height: calc(100vh - 102px);
          // COMMENT MOBILE END
        }
      }
      &__wrapper {
        width: 100%;
        height: 490px;
        margin-bottom: 24px;
        @media only screen and (max-width: 1024px) {
          height: 220px;
          margin: 0;
        }
        @media screen and (min-device-width: 820px) and (max-device-width: 1024px) {
          height: 300px;
          margin: 0;
        }
        @media only screen and (max-width: 375px) {
          height: 210px;
          margin: 0;
        }

        &.setHeightSoundCloud {
          height: fit-content !important;
          @media only screen and (max-width: 1024px) {
            height: 98px !important;
            position: fixed;
            bottom: 0;
            z-index: 2000;
          }
        }
        &.setHeight {
          // COMMENT MOBILE START
          position: fixed;
          top: 102px;
          left: 0;
          right: 0;
          height: 220px;
          @media only screen and (max-width: 375px) {
            height: 210px;
          }
          ::v-deep #video-player {
            min-height: 100%;
            max-height: 100%;
          }
          // COMMENT MOBILE END
        }
        ::v-deep iframe {
          width: 100%;
          height: 100%;
        }
      }
      &__detail {
        @media only screen and (max-width: 1024px) {
          padding: 24px 20px 0;
          position: relative;
        }
        #accordionExample {
          .accordion-item {
            background: transparent;
            border: none;
            .title-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 8px;
              .title_ {
                font-size: 24px;
                font-weight: 500;
                line-height: 34px;
                margin: 0;
                padding-right: 50px;
                @media only screen and (max-width: 1024px) {
                  font-size: 22px;
                  line-height: 30px;
                }
              }
              .show-desc {
                transition: 0.1s ease;
                transform: unset;
                cursor: pointer;
                svg {
                  width: 12px;
                  height: auto;
                  @media only screen and (max-width: 1024px) {
                    width: 12px;
                  }
                }
                &.rotate {
                  transform: rotate(180deg);
                }
              }
            }
            .share-wrapper {
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              cursor: pointer;
              .icon {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                @media only screen and (max-width: 1024px) {
                  width: 16px;
                  height: 16px;
                  margin-right: 8px;
                }
              }
              .text {
                font-size: 16px;
                font-weight: 500;
                color: #4a25aa;
                margin: 0;
                text-decoration: underline;
                @media only screen and (max-width: 1024px) {
                  font-size: 14px;
                }
              }
            }
            .desc-wrapper {
              .accordion-body {
                padding: 16px 0 24px;
                border-top: 1px solid lightgray;
                ::v-deep p {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  margin-bottom: 24px;
                  @media only screen and (max-width: 1024px) {
                    margin-bottom: 20px;
                  }
                  br {
                    display: none;
                  }
                  img {
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                  }
                  figure {
                    padding-top: 8px;
                    @media only screen and (max-width: 1024px) {
                      padding-top: 4px;
                    }
                  }
                  iframe {
                    padding-top: 8px;
                    @media only screen and (max-width: 1024px) {
                      padding-top: 4px;
                      max-width: 100%;
                      max-height: 56.25vw;
                    }
                    &.instagram-media {
                      @media only screen and (max-width: 1024px) {
                        padding-top: 4px;
                        max-width: 100%;
                        max-height: unset;
                      }
                    }
                  }
                  span {
                    img {
                      width: unset;
                      height: unset;
                    }
                  }
                  a {
                    img {
                      width: auto;
                    }
                  }
                  h3 {
                    padding-top: 8px;
                    margin-bottom: 16px;
                    @media only screen and (max-width: 1024px) {
                      padding-top: 4px;
                      margin-bottom: 14px;
                    }
                  }
                  h4,
                  h5 {
                    margin-bottom: 12px;
                    padding-top: 8px;
                    @media only screen and (max-width: 1024px) {
                      padding-top: 4px;
                    }
                  }
                  blockquote {
                    position: relative;
                    padding-left: 40px;
                    margin-left: 40px;
                    @media only screen and (max-width: 1024px) {
                      padding-left: 32px;
                      margin-left: 0;
                    }
                    p {
                      font-size: 16px;
                      color: #051c2c;
                      line-height: 32px;
                      @media only screen and (max-width: 1024px) {
                        line-height: 28px;
                      }
                    }
                    &:before {
                      content: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7148 0.910156V1.86719C15.3607 2.36198 14.2995 3.14323 13.5312 4.21094C12.763 5.26562 12.3789 6.36589 12.3789 7.51172C12.3789 7.75911 12.418 7.94141 12.4961 8.05859C12.5482 8.13672 12.6068 8.17578 12.6719 8.17578C12.737 8.17578 12.8346 8.13021 12.9648 8.03906C13.3815 7.73958 13.9089 7.58984 14.5469 7.58984C15.2891 7.58984 15.9466 7.89583 16.5195 8.50781C17.0924 9.10677 17.3789 9.82292 17.3789 10.6562C17.3789 11.5286 17.0469 12.2969 16.3828 12.9609C15.7318 13.625 14.9375 13.957 14 13.957C12.9062 13.957 11.9622 13.5078 11.168 12.6094C10.3737 11.7109 9.97656 10.5065 9.97656 8.99609C9.97656 7.23828 10.5169 5.66276 11.5977 4.26953C12.6784 2.8763 14.3841 1.75651 16.7148 0.910156ZM7.32031 0.910156V1.86719C5.96615 2.36198 4.90495 3.14323 4.13672 4.21094C3.36849 5.26562 2.98438 6.36589 2.98438 7.51172C2.98438 7.75911 3.02344 7.94141 3.10156 8.05859C3.15365 8.13672 3.21224 8.17578 3.27734 8.17578C3.34245 8.17578 3.4401 8.13021 3.57031 8.03906C3.98698 7.73958 4.51432 7.58984 5.15234 7.58984C5.89453 7.58984 6.55208 7.89583 7.125 8.50781C7.69792 9.10677 7.98438 9.82292 7.98438 10.6562C7.98438 11.5286 7.65234 12.2969 6.98828 12.9609C6.33724 13.625 5.54297 13.957 4.60547 13.957C3.51172 13.957 2.56771 13.5078 1.77344 12.6094C0.979167 11.7109 0.582031 10.5065 0.582031 8.99609C0.582031 7.23828 1.1224 5.66276 2.20312 4.26953C3.28385 2.8763 4.98958 1.75651 7.32031 0.910156Z' fill='black'/%3E%3C/svg%3E%0A");
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: 60%;
                      margin-top: auto;
                      margin-bottom: auto;
                      @media only screen and (max-width: 1024px) {
                        height: 55%;
                      }
                    }
                  }
                }
              }
              .tags {
                display: flex;
                margin: 32px 0;
                .text {
                  margin: 0;
                  font-size: 20px;
                  color: #616161;
                  @media only screen and (max-width: 1024px) {
                    font-size: 14px;
                  }
                }
                .tag-wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: row;
                  align-items: center;
                  row-gap: 0.5vw;
                  @media only screen and (max-width: 1024px) {
                    row-gap: 2vw;
                  }
                  .tag {
                    margin: 0 0 0 0.5vw;
                    padding: 0.2vw 0.5vw;
                    background: #e0e0e0;
                    border-radius: 0.2vw;
                    font-size: 16px;
                    color: #616161;
                    @media only screen and (max-width: 1024px) {
                      margin: 0 0 0 2vw;
                      padding: 1vw 2.5vw;
                      border-radius: 1vw;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        .channel-and-category {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 5px;
          }
          .channel-name,
          .category-name {
            font-size: 16px;
            font-weight: 400;
            color: #4a25aa;
            margin: 0;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
            }
          }
          span {
            font-size: 20px;
            color: #4a25aa;
            margin: 0 10px;
            line-height: 20px;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }
      &__comment {
        .title-wrapper {
          padding: 16px 0;
          border-top: 1px solid lightgray;
          border-bottom: 1px solid lightgray;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media only screen and (max-width: 1024px) {
            padding: 16px 20px;
          }

          .filter {
            cursor: pointer;
            // .arrow {
            //   @media only screen and (max-width: 1024px) {
            //     transform: rotate(180deg);
            //   }
            // }
            .dropdown-toggle {
              display: flex;
              align-items: center;
              &::after {
                display: none;
              }
              .text {
                margin: 0 20px 0 0;
                font-size: 12px;
                color: #4a25aa;
                @media only screen and (max-width: 1024px) {
                  margin-right: 10px;
                }
              }
              svg {
                width: 12px;
                height: 12px;
              }
            }
            .dropdown-menu {
              padding: 0;
              border-radius: 10px;
              overflow: hidden;
              li {
                div {
                  font-size: 12px;
                  padding: 12px 15px;
                  &:hover {
                    background: #4a25aa;
                    color: white !important;
                  }
                }
              }
            }
          }
        }
        .comments {
          @media only screen and (max-width: 1024px) {
            padding: 0 20px;
          }
          .type-comment {
            height: fit-content;
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
            &__profile-image {
              display: flex;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
            }
            &__input {
              width: calc(100% - 60px);
              align-self: center;
              textarea {
                font-size: 16px;
                color: #757575;
                height: fit-content;
                border-radius: 6px;
                &::placeholder {
                  color: #c2c1c6;
                  box-shadow: none;
                }
              }
              .disclaimer {
                font-size: 10px;
                color: #c2c1c6;
                margin: 4px 0;
              }
              .btn-wrapper {
                display: flex;
                justify-content: flex-end;
                margin-top: 8px;
                .btn-cancel {
                  font-size: 14px;
                  font-weight: 500;
                  color: #9e9e9e;
                  margin-right: 15px;
                  padding: 5px 0;
                  cursor: pointer;
                }
                .btn-send {
                  font-size: 14px;
                  font-weight: 500;
                  color: #4a25aa;
                  padding: 5px 0;
                  cursor: pointer;
                }
              }
            }
            &__send {
              width: 25px;
              height: auto;
              align-self: center;
              cursor: pointer;
              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
          &__no {
            margin: 24px auto;
            padding: 30px 50px;
            background: #e0e0e0;
            width: fit-content;
            max-width: 400px;
            border-radius: 10px;
            text-align: center;
            svg {
              width: 50px;
              height: 50px;
              margin-bottom: 20px;
            }
            .desc {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 8px;
            }
            .sub-desc {
              font-size: 12px;
              font-weight: 400;
              color: #616161;
              margin: 0;
            }
          }
          &__card {
            display: flex;
            padding: 16px 0;
            border-bottom: 1px solid lightgray;
            &:last-child {
              border-bottom: none;
            }
            &__profile-image {
              .profile-picture {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
              }
              ::v-deep img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
              }
            }
            &__detail {
              width: -webkit-fill-available;
              padding-left: 20px;
              .comment-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                @media only screen and (max-width: 1024px) {
                  margin-bottom: 5px;
                }
                &__copy {
                  display: flex;
                  align-items: center;
                  &__username {
                    font-size: 14px;
                    font-weight: 700;
                    margin-right: 30px;
                    margin-bottom: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 12px;
                      margin-right: 20px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      -webkit-line-clamp: 1;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      max-width: 80px;
                    }
                  }
                  &__time {
                    font-size: 12px;
                    font-weight: 400;
                    color: #757575;
                    margin: 0;
                    @media only screen and (max-width: 1024px) {
                      font-size: 10px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      -webkit-line-clamp: 1;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      max-width: 80px;
                    }
                  }
                }
                &__dots {
                  width: 20px;
                  height: auto;
                  cursor: pointer;
                  @media only screen and (max-width: 1024px) {
                    width: 15px;
                  }
                  svg {
                    width: 100%;
                    height: auto;
                  }
                }
              }
              .comment-body {
                @media only screen and (max-width: 1024px) {
                  margin-bottom: 8px;
                }
                &__desc {
                  font-size: 12px;
                  font-weight: 400;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  -ms-word-break: break-all;
                  word-break: break-all;
                  word-break: break-word;
                  -ms-hyphens: auto;
                  -moz-hyphens: auto;
                  -webkit-hyphens: auto;
                  hyphens: auto;
                }
              }
              .comment-footer {
                display: flex;
                align-items: center;
                &.addMargin {
                  margin-bottom: 16px;
                }
                &__like {
                  display: flex;
                  align-items: center;
                  margin-right: 20px;
                  cursor: pointer;
                  svg {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    @media only screen and (max-width: 1024px) {
                      width: 12px;
                      height: 12px;
                      margin-right: 8px;
                    }
                  }
                  .total-like {
                    font-size: 12px;
                    font-weight: 400;
                    color: #616161;
                    margin: 0;
                  }
                }
                &__reply {
                  cursor: pointer;
                  margin-right: 20px;
                  display: flex;
                  align-items: center;
                  svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                  }
                  .text {
                    font-size: 12px;
                    font-weight: 400;
                    color: #616161;
                    margin: 0;
                  }
                }
              }
              .show-more-reply {
                display: flex;
                align-items: center;
                cursor: pointer;
                svg {
                  width: 8px;
                  height: 8px;
                  margin-right: 6px;
                  transition: 0.3s ease;
                  &.rotate {
                    transform: rotate(180deg);
                  }
                }
                p {
                  font-size: 12px;
                  font-weight: 400;
                  color: #616161;
                  margin: 0;
                }
              }
            }
          }
        }
        .show-more-comment {
          margin: 0 auto;
          font-size: 14px;
          font-weight: 500;
          color: #4a25aa;
          text-decoration: underline;
          display: flex;
          justify-content: center;
          margin-top: 24px;
          cursor: pointer;
          @media only screen and (max-width: 1024px) {
            padding-bottom: 24px;
          }
        }
      }
    }
    .playlist-video {
      width: 30%;
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      &__selanjutnya {
        padding-bottom: 32px;
        border-bottom: 1px solid lightgray;
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            padding: 16px 20px;
            margin: 0;
            border-top: 1px solid lightgray;
          }
          &__switch {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            label {
              font-size: 12px;
              font-weight: 500;
              color: #4a25aa;
              margin-right: 10px;
            }
            input {
              margin: 0;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
      &__populer {
        margin-top: 32px;
        @media only screen and (max-width: 1024px) {
          padding: 0 20px;
        }
        .header {
          margin-bottom: 24px;
        }
        .body {
          .list-enter-active {
            transition: all 1s;
          }
          .list-enter /* .list-leave-active below version 2.1.8 */ {
            opacity: 0;
          }
          .list-video-populer {
            margin-bottom: 24px;
            ::v-deep .card-eight {
              &:first-child {
                padding-top: 0;
              }
            }
            .card-video-populer {
              width: 100%;
              &:nth-child(6) {
                padding-top: 0;
              }
            }
          }
          .button-show-more {
            text-align: center;
            span {
              cursor: pointer;
              height: 50px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #4a25aa;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 700;
              color: #4a25aa;
              padding: 0 20px;
              @media only screen and (max-width: 1024px) {
                height: 40px;
                &:hover {
                  color: #4a25aa !important;
                }
              }
              @media only screen and (min-width: 1024px) {
                &:hover {
                  color: #8166c8 !important;
                  border-color: #8166c8 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.jadwal-imsak-container {
  padding-bottom: 16px;
  @media only screen and (max-width: 1024px) {
    padding-bottom: 8px;
  }
}
</style>
