var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"watch",class:{ removePadding: _vm.$route.query.comment === 'show' }},[_c('ModalShare',{on:{"registeredModal":function($event){_vm.modalShare = $event}}}),_vm._v(" "),_c('ModalLogin',{attrs:{"redirect":_vm.$route.path},on:{"registeredModal":function($event){_vm.modalLogin = $event}}}),_vm._v(" "),_c('ModalReportComment',{attrs:{"commentId":_vm.comment_id},on:{"registeredModal":function($event){_vm.modalReportComment = $event},"event":_vm.reportComment}}),_vm._v(" "),_c('VideoPageTopAds'),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"video",class:{ setHeight: _vm.$route.query.comment === 'show' }},[_c('ShimmerWatchVideo',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"video__wrapper",class:[
            {
              setHeight: _vm.$route.query.comment === 'show',
            },
            {
              setHeightSoundCloud:
                _vm.response.data.contentUrl.default === 'soundcloud',
            },
          ]},[(
              _vm.response.data.contentUrl &&
              _vm.response.data.contentUrl.default === 'youtube' &&
              _vm.response.data.contentUrl.content.length > 0
            )?[_c('div',{attrs:{"id":"playerYoutube"}})]:(
              _vm.response.data.contentUrl &&
              _vm.response.data.contentUrl.default === 'soundcloud' &&
              _vm.response.data.contentUrl.content.length > 0
            )?[_c('div',{staticClass:"embed-responsive embed-responsive-16by9"},[_c('iframe',{staticClass:"embed-responsive-item",attrs:{"src":_vm.response.data.contentUrl.content[0].url}})])]:(
              _vm.response.data.contentUrl &&
              _vm.response.data.contentUrl.content.length === 0
            )?[_c('ImageResponsive',{staticClass:"img-fluid w-100",attrs:{"imageUrl":_vm.response.data.thumbnail.medium,"width":"877","height":"489","fromUrl":true,"alt":"Narasi TV"}})]:(_vm.response.data.contentUrl.default === 'jwplayer')?[_c('div',{ref:"videoPlayer",staticClass:"ratio ratio-16x9",attrs:{"id":"video-player"}})]:(_vm.response.data.contentUrl.default === 'narasi')?[_c('narasi-player',{attrs:{"options":{
                autoplay: true,
                controls: true,
                preload: 'auto',
                poster: `${_vm.response.data.contentUrl.content[0].url.replace(
                  'manifest.m3u8',
                  'poster.png'
                )}`,
                sources: [
                  {
                    src: _vm.response.data.contentUrl.content[0].url,
                    type: 'application/x-mpegURL',
                  },
                ],
                html5: {
                  nativeAudioTracks: true,
                  nativeVideoTracks: true,
                  nativeTextTracks: true,
                },
              }}})]:_c('ShimmerWatchVideo')],2),_vm._v(" "),_c('div',{staticClass:"video__detail"},[_c('div',{staticClass:"channel-and-category"},[(_vm.response.data.channel)?_c('p',{staticClass:"channel-name"},[_c('Link',{attrs:{"to":{
                  name: 'program-childBrand-slugProgram',
                  params: {
                    childBrand: _vm.response.data.channel.program.slug,
                    slugProgram: _vm.response.data.channel.slug,
                  },
                  query: {
                    type: 'highlights',
                  },
                }}},[_vm._v(_vm._s(_vm.response.data.channel.title))])],1):_vm._e(),_vm._v(" "),_c('span',[_vm._v("•")]),_vm._v(" "),(_vm.response.data.category)?_c('p',{staticClass:"category-name"},[_c('Link',{attrs:{"to":{
                  name: 'interest',
                  query: {
                    type: _vm.response.data.category.slug,
                  },
                }}},[_vm._v(_vm._s(_vm.response.data.category.title))])],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"accordion",attrs:{"id":"accordionExample"}},[_c('div',{staticClass:"accordion-item"},[_c('div',{staticClass:"title-wrapper"},[_c('h1',{staticClass:"title_"},[_vm._v("\n                  "+_vm._s(_vm.response.data.title)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"collapsed show-desc",class:{ rotate: _vm.flipArrow == 'true' },attrs:{"id":"accord-1","data-bs-toggle":"collapse","data-bs-target":"#video-desc","aria-expanded":"true","aria-controls":"video-desc"},on:{"click":function($event){return _vm.nextStep('top-accordion')}}},[_c('Icons',{staticClass:"arrow-bottom",attrs:{"name":"arrow-bottom"}})],1)]),_vm._v(" "),_c('div',{staticClass:"share-wrapper share-video",on:{"click":function($event){return _vm.modalShare.show()}}},[_c('Icons',{staticClass:"icon",attrs:{"name":"bagikan","color":"#4A25AA"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Bagikan")])],1),_vm._v(" "),_c('div',{staticClass:"accordion-collapse collapse show desc-wrapper",attrs:{"id":"video-desc","aria-labelledby":"headingOne","data-bs-parent":"#accordionExample"}},[(
                    _vm.response.data.articlePart &&
                    _vm.response.data.articlePart.length > 0
                  )?_c('div',{staticClass:"accordion-body"},_vm._l((_vm.page !== 'full'
                      ? _vm.response.data.articlePart.slice(0, 1)
                      : _vm.response.data.articlePart),function(article,index){return _c('p',{key:`desc-article-${index}`,domProps:{"innerHTML":_vm._s(
                      article.description
                        .replace(/<p><br[\/]?><[\/]?p>/g, '')
                        .replace(/<p>&nbsp;<[\/]?p>/g, '')
                    )}})}),0):_c('div',{staticClass:"accordion-body",domProps:{"innerHTML":_vm._s(
                    _vm.response.data.content
                      ? _vm.response.data.content
                          .replace(/<p><br[\/]?><[\/]?p>/g, '')
                          .replace(/<p>&nbsp;<[\/]?p>/g, '')
                      : ''
                  )}}),_vm._v(" "),(_vm.response.data.tags && _vm.response.data.tags.length)?_c('div',{staticClass:"tags"},[_c('p',{staticClass:"text"},[_vm._v("Tags:")]),_vm._v(" "),_c('div',{staticClass:"tag-wrapper"},_vm._l((_vm.response.data.tags),function(item){return _c('Link',{key:item.id,staticClass:"tag",attrs:{"to":{
                        name: 'tags-slug',
                        params: {
                          slug: item.slug,
                        },
                      }}},[_vm._v(_vm._s(item.topic))])}),1)]):_vm._e()])])])]),_vm._v(" "),_c('div',{staticClass:"video__comment"},[_c('div',{staticClass:"title-wrapper header"},[_c('TitleSection',{attrs:{"title":"KOMENTAR","size":"medium"}}),_vm._v(" "),_c('div',{staticClass:"dropdown filter"},[_c('div',{staticClass:"dropdown-toggle",attrs:{"id":"dropdownMenuSortComment","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.sortLabel))]),_vm._v(" "),_c('div',{staticClass:"arrow"},[_c('Icons',{attrs:{"name":"arrow-bottom"}})],1)]),_vm._v(" "),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuSortComment"}},_vm._l((_vm.sortComment),function(sortItem,indexSort){return _c('li',{key:'sort-' + indexSort,on:{"click":function($event){_vm.getSortCommentBy(sortItem.param),
                      (_vm.sortLabel = sortItem.label)}}},[_c('div',{staticClass:"dropdown-item"},[_vm._v(_vm._s(sortItem.label))])])}),0)])],1),_vm._v(" "),_c('div',{staticClass:"comments"},[_c('div',{staticClass:"type-comment"},[_c('div',{staticClass:"type-comment__profile-image"},[(
                    _vm.$store.state.auth.user && _vm.$store.state.auth.user.picture
                  )?_c('ImageResponsive',{attrs:{"imageUrl":_vm.$store.state.auth.user.picture,"fromUrl":true}}):_c('ProfilePicture',{attrs:{"initial":_vm.$store.state.auth.user && _vm.$store.state.auth.user.fullname
                      ? _vm.$store.state.auth.user.fullname.substr(0, 1)
                      : ''}})],1),_vm._v(" "),_c('div',{staticClass:"type-comment__input"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],ref:"type_comment_mobile",staticClass:"form-control",attrs:{"aria-label":"With textarea","placeholder":"Tulis Komentar...","rows":_vm.btnSend ? 4 : 1,"maxlength":_vm.limitCharComment},domProps:{"value":(_vm.comment)},on:{"focus":function($event){_vm.$store.state.auth.loggedIn
                      ? (_vm.btnSend = true)
                      : _vm.modalLogin.show()},"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}}),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnSend),expression:"btnSend"}],staticClass:"disclaimer"},[_vm._v("\n                  Anda sepenuhnya bertanggung jawab atas komentar yang\n                  diberikan, hindari ujaran melanggar hukum.\n                ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnSend),expression:"btnSend"}],staticClass:"btn-wrapper"},[_c('div',{staticClass:"btn-cancel",on:{"click":function($event){_vm.btnSend = false}}},[_vm._v("BATAL")]),_vm._v(" "),_c('div',{staticClass:"btn-send",on:{"click":function($event){return _vm.postComment('main')}}},[_vm._v("\n                    KIRIM\n                  ")])])])]),_vm._v(" "),(_vm.comments.loading)?_c('div',{staticClass:"p-5"},[_c('div',{staticClass:"dot-spin m-auto"})]):_vm._e(),_vm._v(" "),(_vm.comments.items.length === 0 && !_vm.comments.loading)?_c('div',{staticClass:"comments__no"},[_c('Icons',{staticClass:"no-comment-icon",attrs:{"name":"no-comment"}}),_vm._v(" "),_c('p',{staticClass:"desc"},[_vm._v("Belum ada komentar")]),_vm._v(" "),_c('p',{staticClass:"sub-desc"},[_vm._v("\n                Jadi yang pertama mengirimkan komentar dan berinteraksi dengan\n                pengguna lain\n              ")])],1):_vm._e(),_vm._v(" "),_vm._l((_vm.$store.state.videos.comments
                .items),function(item,index_comment){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.comments && _vm.comments.items.length > 0),expression:"comments && comments.items.length > 0"}],key:index_comment,staticClass:"comments__card"},[_c('div',{staticClass:"comments__card__profile-image"},[(item.commenter && item.commenter.picture)?_c('ImageResponsive',{attrs:{"imageUrl":item.commenter.picture,"fromUrl":true}}):_c('ProfilePicture',{attrs:{"initial":item.commenter && item.commenter.fullname
                      ? item.commenter.fullname.substr(0, 1)
                      : ''}})],1),_vm._v(" "),_c('div',{staticClass:"comments__card__detail"},[_c('div',{staticClass:"comment-header"},[_c('div',{staticClass:"comment-header__copy"},[_c('h2',{staticClass:"comment-header__copy__username"},[_vm._v("\n                      "+_vm._s(item.commenter.fullname)+"\n                    ")]),_vm._v(" "),_c('p',{staticClass:"comment-header__copy__time"},[_vm._v("\n                      "+_vm._s(item.createdAt)+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"comment-header__dots",on:{"click":function($event){_vm.$store.state.auth.loggedIn
                        ? [_vm.modalReportComment.show(), _vm.setCommentId(item.id)]
                        : _vm.modalLogin.show()}}},[_c('Icons',{staticClass:"dots",attrs:{"name":"dots-option"}})],1)]),_vm._v(" "),_c('div',{staticClass:"comment-body"},[_c('p',{staticClass:"comment-body__desc",attrs:{"id":"desc"},domProps:{"innerHTML":_vm._s(item.comment)}})]),_vm._v(" "),_c('div',{staticClass:"comment-footer",class:item.children.length > 0 ? 'addMargin' : ''},[_c('div',{staticClass:"comment-footer__like",on:{"click":function($event){return _vm.toggleLike(
                        item,
                        index_comment,
                        _vm.response.data.slug,
                        'comment'
                      )}}},[_c('Icons',{attrs:{"name":"like","color":item.isLiked ? '#4A25AA' : '#616161'}}),_vm._v(" "),_c('p',{staticClass:"total-like"},[_vm._v("\n                      "+_vm._s(item.likes == 0 ? "suka" : item.likes + " suka")+"\n                    ")])],1),_vm._v(" "),_c('div',{staticClass:"comment-footer__reply",on:{"click":function($event){return _vm.replyComment(index_comment, item.id)}}},[_c('Icons',{attrs:{"name":"reply"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("Balas Komentar")])],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index_comment === _vm.subComment.indexComment),expression:"index_comment === subComment.indexComment"}],staticClass:"type-comment sub-type-comment",class:item.children.length > 0 ? 'addPadding' : ''},[_c('div',{staticClass:"type-comment__profile-image"},[(
                        _vm.$store.state.auth.user &&
                        _vm.$store.state.auth.user.picture
                      )?_c('ImageResponsive',{attrs:{"imageUrl":_vm.$store.state.auth.user.picture,"fromUrl":true}}):_c('ProfilePicture',{attrs:{"initial":_vm.$store.state.auth.user &&
                        _vm.$store.state.auth.user.fullname
                          ? _vm.$store.state.auth.user.fullname.substr(0, 1)
                          : ''}})],1),_vm._v(" "),_c('div',{staticClass:"type-comment__input"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.subComment.mobile.value),expression:"subComment.mobile.value"}],ref:"type_comment_mobile",refInFor:true,staticClass:"form-control",attrs:{"aria-label":"With textarea","placeholder":"Tulis Komentar...","rows":"1","maxlength":"400"},domProps:{"value":(_vm.subComment.mobile.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.subComment.mobile, "value", $event.target.value)}}}),_vm._v(" "),_c('p',{staticClass:"disclaimer"},[_vm._v("\n                      Anda sepenuhnya bertanggung jawab atas komentar yang\n                      diberikan, hindari ujaran melanggar hukum.\n                    ")]),_vm._v(" "),_c('div',{staticClass:"btn-wrapper"},[_c('div',{staticClass:"btn-cancel",on:{"click":function($event){return _vm.replyComment(index_comment, item.id)}}},[_vm._v("\n                        BATAL\n                      ")]),_vm._v(" "),_c('div',{staticClass:"btn-send",on:{"click":function($event){return _vm.postComment('subMobile')}}},[_vm._v("\n                        KIRIM\n                      ")])])])]),_vm._v(" "),_c('transition-group',{attrs:{"name":"list"}},_vm._l((item.children),function(subItem,index_sub_comment){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index_comment === _vm.subComment.indexShowSubComment),expression:"index_comment === subComment.indexShowSubComment"}],key:'subComment ' + index_sub_comment,staticClass:"comments__card"},[_c('div',{staticClass:"comments__card__profile-image"},[(subItem.commenter && subItem.commenter.picture)?_c('ImageResponsive',{attrs:{"imageUrl":subItem.commenter.picture,"fromUrl":true}}):_c('ProfilePicture',{attrs:{"initial":subItem.commenter && subItem.commenter.fullname
                            ? subItem.commenter.fullname.substr(0, 1)
                            : ''}})],1),_vm._v(" "),_c('div',{staticClass:"comments__card__detail"},[_c('div',{staticClass:"comment-header"},[_c('div',{staticClass:"comment-header__copy"},[_c('h2',{staticClass:"comment-header__copy__username"},[_vm._v("\n                            "+_vm._s(subItem.commenter.fullname)+"\n                          ")]),_vm._v(" "),_c('p',{staticClass:"comment-header__copy__time"},[_vm._v("\n                            "+_vm._s(subItem.createdAt)+"\n                          ")])]),_vm._v(" "),_c('div',{staticClass:"comment-header__dots",on:{"click":function($event){_vm.$store.state.auth.loggedIn
                              ? [
                                  _vm.modalReportComment.show(),
                                  _vm.setCommentId(subItem.id),
                                ]
                              : _vm.modalLogin.show()}}},[_c('Icons',{staticClass:"dots",attrs:{"name":"dots-option"}})],1)]),_vm._v(" "),_c('div',{staticClass:"comment-body"},[_c('p',{staticClass:"comment-body__desc",domProps:{"innerHTML":_vm._s(subItem.comment)}})]),_vm._v(" "),_c('div',{staticClass:"comment-footer"},[_c('div',{staticClass:"comment-footer__like",on:{"click":function($event){return _vm.toggleLike(
                              subItem,
                              index_comment,
                              _vm.response.data.slug,
                              'sub-comment',
                              index_sub_comment
                            )}}},[_c('Icons',{attrs:{"name":"like","color":subItem.isLiked ? '#4A25AA' : '#616161'}}),_vm._v(" "),_c('p',{staticClass:"total-like"},[_vm._v("\n                            "+_vm._s(subItem.likes == 0
                                ? "suka"
                                : subItem.likes + " suka")+"\n                          ")])],1)])])])}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.children.length > 0),expression:"item.children.length > 0"}],staticClass:"show-more-reply",on:{"click":function($event){return _vm.showSubComment(index_comment)}}},[_c('Icons',{class:{
                      rotate:
                        item.children.length ===
                          _vm.comments.items[index_comment].children.length &&
                        index_comment === _vm.subComment.indexShowSubComment,
                    },attrs:{"name":"arrow-bottom","color":"#616161"}}),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.getReplyText(item.children.length, index_comment))+"\n                  ")])],1)],1)])})],2),_vm._v(" "),(
              !_vm.comments.loading &&
              !_vm.loadingShowMoreComment &&
              _vm.comments.items.length != _vm.comments.pagination.total
            )?_c('div',{staticClass:"show-more-comment",on:{"click":function($event){return _vm.loadMoreComments()}}},[_vm._v("\n            Lihat Komentar Lainnya\n          ")]):_vm._e(),_vm._v(" "),(_vm.loadingShowMoreComment)?_c('div',{staticClass:"dot-spin-wrapper"},[_c('div',{staticClass:"dot-spin m-auto"})]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"playlist-video"},[_c('VideoPageTopRightAds'),_vm._v(" "),_c('div',{staticClass:"playlist-video__selanjutnya"},[_c('div',{staticClass:"header"},[_c('TitleSection',{staticClass:"header__title",attrs:{"title":"SELANJUTNYA","size":"medium"}}),_vm._v(" "),_c('div',{staticClass:"form-check form-switch header__switch"},[_c('label',{staticClass:"form-check-label",attrs:{"for":"flexSwitchCheckDefault"}},[_vm._v("Autoplay")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.autoplay),expression:"autoplay"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"flexSwitchCheckDefault"},domProps:{"checked":Array.isArray(_vm.autoplay)?_vm._i(_vm.autoplay,null)>-1:(_vm.autoplay)},on:{"change":function($event){var $$a=_vm.autoplay,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.autoplay=$$a.concat([$$v]))}else{$$i>-1&&(_vm.autoplay=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.autoplay=$$c}}}})])],1),_vm._v(" "),_c('div',{staticClass:"body"},[_c('div',{staticClass:"list-video-selanjutnya"},[(_vm.selanjutnya.loading)?_c('ShimmerCardHomeLandscapeSmall'):_c('CardNine',{staticClass:"card-video-selanjutnya",attrs:{"image":_vm.selanjutnya.items.thumbnail
                    ? _vm.selanjutnya.items.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":_vm.selanjutnya.items.timeVideo
                    ? _vm.selanjutnya.items.timeVideo
                    : '00:00',"category":_vm.selanjutnya.items.category
                    ? _vm.selanjutnya.items.category.title
                    : '',"channel":_vm.selanjutnya.items.channel
                    ? _vm.selanjutnya.items.channel.title
                    : '',"channelSlug":_vm.selanjutnya.items.channel.slug,"programSlug":_vm.selanjutnya.items.channel &&
                  _vm.selanjutnya.items.channel.program
                    ? _vm.selanjutnya.items.channel.program.slug
                    : '',"title":_vm.selanjutnya.items.title,"isText":_vm.selanjutnya.items.isText,"isAdvertorial":_vm.selanjutnya.items.isAdvertorial,"to":_vm.selanjutnya.items.isText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: _vm.selanjutnya.items.channel.slug,
                          slug: _vm.selanjutnya.items.slug
                            ? _vm.selanjutnya.items.slug
                            : _vm.$route.params.slug,
                        },
                        query: {
                          ref: 'video-selanjutnya',
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: _vm.selanjutnya.items.channel.slug,
                          slug: _vm.selanjutnya.items.slug
                            ? _vm.selanjutnya.items.slug
                            : _vm.$route.params.slug,
                        },
                        query: {
                          ref: 'video-selanjutnya',
                        },
                      }}})],1)])]),_vm._v(" "),_c('div',{staticClass:"playlist-video__populer"},[_c('div',{staticClass:"header"},[_c('TitleSection',{staticClass:"header__title",attrs:{"title":"TERPOPULER","size":"medium"}})],1),_vm._v(" "),_c('div',{staticClass:"body"},[_c('transition-group',{staticClass:"list-video-populer",attrs:{"name":"list","tag":"div"}},[_vm._l((5),function(item){return _c('ShimmerCardHomeLandscapeSmall',{directives:[{name:"show",rawName:"v-show",value:(_vm.populer.loading),expression:"populer.loading"}],key:item})}),_vm._v(" "),_vm._l((_vm.populer.items),function(item){return _c('CardEight',{directives:[{name:"show",rawName:"v-show",value:(!_vm.populer.loading),expression:"!populer.loading"}],key:item.id,staticClass:"card-video-populer",attrs:{"image":item.thumbnail
                    ? item.thumbnail.large
                    : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '00:00',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"channel":item.channel ? item.channel.title : '',"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"to":item.isText
                    ? {
                        name: `read-channel-slug`,
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                        query: {
                          ref: 'terpopuler-detail',
                        },
                      }
                    : {
                        name: 'video-channel-slug',
                        params: {
                          channel: item.channel.slug,
                          slug: item.slug,
                        },
                        query: {
                          ref: 'terpopuler-detail',
                        },
                      }}})})],2),_vm._v(" "),_c('div',{staticClass:"button-show-more",class:{ 'mt-5': _vm.populer.loadingShowMore }},[(
                  _vm.populer.items.length < _vm.populer.totalItem &&
                  !_vm.populer.loadingShowMore
                )?_c('span',{on:{"click":_vm.nextPopuler}},[_vm._v("LIHAT LEBIH BANYAK")]):_vm._e(),_vm._v(" "),(_vm.populer.loadingShowMore)?_c('div',{staticClass:"dot-spin m-auto"}):_vm._e()]),_vm._v(" "),_c('AdsHorizontalFixedProps',{staticClass:"mt-3",attrs:{"width":"315px","height":"290px","id":"props_placement_before_populer"}})],1)])],1)])]),_vm._v(" "),_c('VideoPageBottomAds')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }