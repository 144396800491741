<template>
  <div class="terpopuler-page">
    <Breadcrumbs background="#f1f1f1" />
    <section class="headline">
      <div class="container">
        <h2 class="headline__title">Video Terpopuler</h2>
        <p class="headline__desc">Kumpulan video terpopuler di Narasi TV</p>
      </div>
    </section>
    <section class="list-terpopuler">
      <div class="container list-card-terpopuler">
        <ShimmerCardHomeLandscape
          v-show="terpopuler.loading"
          v-for="(item, index) in 5"
          :key="index"
        />
        <CardFive
          v-show="!terpopuler.loading"
          v-for="item in terpopuler.items"
          :key="item.id"
          :image="
            item.thumbnail && item.thumbnail.large
              ? item.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy.png'
          "
          :imageFromUrl="true"
          :duration="item.timeVideo ? item.timeVideo : '00:00'"
          :channel="item.channel.title"
          :channelSlug="item.channel.slug"
          :programSlug="item.channel.program ? item.channel.program.slug : ''"
          :category="item.category ? item.category.title : ''"
          :categorySlug="item.category ? item.category.slug : ''"
          :title="item.title"
          :isText="item.isText"
          :isAdvertorial="item.isAdvertorial"
          :to="
            item.isText
              ? {
                  name: `read-channel-slug`,
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                }
              : {
                  name: 'video-channel-slug',
                  params: {
                    channel: item.channel.slug,
                    slug: item.slug,
                  },
                }
          "
        />
      </div>
      <div
        class="pagination-cards"
        v-if="terpopuler.items.length > 0 && terpopuler.pagination.pages > 1"
      >
        <paginate
          v-if="
            terpopuler.pagination &&
            terpopuler.pagination.pages &&
            terpopuler.pagination.pages > 1
          "
          :page-count="terpopuler.pagination.pages"
          :page-range="3"
          :margin-pages="1"
          prev-class="arrow"
          next-class="arrow"
          :click-handler="clickCallback"
          :container-class="'pagination'"
          :page-class="'page-item'"
          v-model="page"
        >
          <template slot="prevContent">
            <Icons name="arrow-left" color="#757575" />
          </template>
          <template slot="nextContent">
            <Icons name="arrow-right" color="#757575" />
          </template>
        </paginate>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import config from "../../config";
import CardFive from "/components/cards/CardFive.vue";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";

Vue.component("paginate", Paginate);

export default {
  async asyncData({ error, store, route }) {
    try {
      store.commit("seo/SET_SEO", {
        title: "Video Terpopuler",
        desc: "Kumpulan video terpopuler di Narasi TV",
        image: "https://narasi.tv/new-icon.png",
        url: `${config.BASE_URL}${route.fullPath}`,
        path: `${config.BASE_URL}${route.path}`,
      });
    } catch (err) {
      error({ statusCode: 404, message: "Page not found" });
    }
  },
  components: {
    CardFive,
  },
  data() {
    return {
      page: 1,
    };
  },
  head() {
    return this.headReturn;
  },
  watch: {
    "$route.query.page": {
      handler: function (page) {
        if (page) {
          let page_ = parseInt(page);
          this.page = page_;
        } else {
          this.page = 1;
        }
        this.initData();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      terpopuler: (state) => {
        return state.home.terpopuler;
      },
    }),
  },
  methods: {
    async initData() {
      await this.$store.dispatch("home/getTerpopuler", {
        page: this.page,
        isVideo: true,
      });
    },
    clickCallback(page) {
      this.page = page;

      this.$router.replace({
        name: "video-terpopuler",
        query: {
          page: page,
        },
      });

      this.$store.dispatch("home/getTerpopuler", {
        page: this.page,
        isVideo: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.terpopuler-page {
  padding: 48px 0;
  @media only screen and (max-width: 1024px) {
    padding: 30px 0;
  }

  .headline {
    margin-bottom: 48px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 24px;
    }

    .container {
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
    }

    &__title {
      font-size: 32px;
      font-weight: 700;
      color: #4a25aa;
      margin-bottom: 16px;
      text-align: center;
      @media only screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }

    &__desc {
      font-size: 16px;
      font-weight: 400;
      color: #757575;
      text-align: center;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        color: #0c0b0d;
      }
    }
  }
  .list-terpopuler {
    .pagination-cards {
      padding: 24px 0;

      .pagination {
        justify-content: center;
        margin: 0;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          justify-content: center;
        }

        ::v-deep .arrow {
          display: none;
        }

        ::v-deep .page-item {
          a {
            font-size: 12px;
            font-weight: 400;
            color: #757575;
            background: #e0e0e0;
            width: 25px;
            height: 25px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          &.active {
            a {
              background: #4a25aa;
              color: #9e9e9e !important;
            }
          }

          &:hover {
            a {
              background: #4a25aa;
              color: #9e9e9e !important;
            }
          }
        }
      }
    }
  }
}
</style>
